import Vue from 'vue'

Vue.use(window.vant.Lazyload)

// import Button from 'vant/lib/button'
// import 'vant/lib/button/style'
//
// import Radio from 'vant/lib/radio'
// import 'vant/lib/radio/style'
// import RadioGroup from 'vant/lib/radio-group'
// import 'vant/lib/radio-group/style'
// import Checkbox from 'vant/lib/checkbox'
// import 'vant/lib/checkbox/style'
// import CheckboxGroup from 'vant/lib/checkbox-group'
// import 'vant/lib/checkbox-group/style'
//
// import CellGroup from 'vant/lib/cell-group'
// import 'vant/lib/cell-group/style'
// import Cell from 'vant/lib/cell'
// import 'vant/lib/cell/style'
// import Row from 'vant/lib/row'
// import 'vant/lib/row/style'
// import Col from 'vant/lib/col'
// import 'vant/lib/col/style'
//
// import Tabbar from 'vant/lib/tabbar'
// import 'vant/lib/tabbar/style'
// import TabbarItem from 'vant/lib/tabbar-item'
// import 'vant/lib/tabbar-item/style'
// import Tabs from 'vant/lib/tabs'
// import 'vant/lib/tabs/style'
// import Tab from 'vant/lib/tab'
// import 'vant/lib/tab/style'
//
// import Field from 'vant/lib/field'
// import 'vant/lib/field/style'
// import DatetimePicker from 'vant/lib/datetime-picker'
// import 'vant/lib/datetime-picker/style'
//
// import Search from 'vant/lib/search'
// import 'vant/lib/search/style'
//
// import Icon from 'vant/lib/icon'
// import 'vant/lib/icon/style'
// import Tag from 'vant/lib/tag'
// import 'vant/lib/tag/style'
//
// import Popup from 'vant/lib/popup'
// import 'vant/lib/popup/style'
// import ActionSheet from 'vant/lib/action-sheet'
// import 'vant/lib/action-sheet/style'
// import Uploader from 'vant/lib/uploader'
// import 'vant/lib/uploader/style'
// import Image from 'vant/lib/image'
// import 'vant/lib/image/style'
// import CountDown from 'vant/lib/count-down'
// import 'vant/lib/count-down/style'
//
// import Toast from 'vant/lib/toast'
// import 'vant/lib/toast/style'
// import Dialog from 'vant/lib/dialog'
// import 'vant/lib/dialog/style'
// import Overlay from 'vant/lib/overlay'
// import 'vant/lib/overlay/style'
// import Loading from 'vant/lib/loading'
// import 'vant/lib/loading/style'
// import Empty from 'vant/lib/empty'
// import 'vant/lib/empty/style'
//
// Vue.use(Button)
// Vue.use(Radio)
// Vue.use(RadioGroup)
// Vue.use(Checkbox)
// Vue.use(CheckboxGroup)
// Vue.use(CellGroup)
// Vue.use(Cell)
// Vue.use(Field)
// Vue.use(Tabbar)
// Vue.use(TabbarItem)
// Vue.use(Search)
// Vue.use(Icon)
// Vue.use(Tag)
// Vue.use(Popup)
// Vue.use(ActionSheet)
// Vue.use(Uploader)
// Vue.use(Image)
// Vue.use(CountDown)
// Vue.use(Row)
// Vue.use(Col)
// Vue.use(Toast)
// Vue.use(Tab)
// Vue.use(Tabs)
// Vue.use(Dialog)
// Vue.use(DatetimePicker)
// Vue.use(Overlay)
// Vue.use(Loading)
// Vue.use(Empty)
