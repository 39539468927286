import Storages from 'js-storage/js.storage.min'
import WxPayApi from '@/apis/WxPayApi'

const storage = Storages.initNamespaceStorage('wxpay').sessionStorage

const state = {
  wxpayEnable: storage.get('wxpayEnable') || false,
  wxpaySandbox: storage.get('wxpaySandbox') || false,
  wxpayWhiteList: storage.get('wxpayWhiteList') || [],
  appId: storage.get('appId') || '',
  entryUrl: storage.get('entryUrl') || '',
  wxAuthUrl: storage.get('wxAuthUrl') || ''
}

const mutations = {
  SET_WXPAY_ENABLE: (state, wxpayEnable) => {
    state.wxpayEnable = wxpayEnable || false
    storage.set('wxpayEnable', state.wxpayEnable)
  },
  SET_WXPAY_SANDBOX: (state, wxpaySandbox) => {
    state.wxpaySandbox = wxpaySandbox || false
    storage.set('wxpaySandbox', state.wxpaySandbox)
  },
  SET_WXPAY_WHITE_LIST: (state, wxpayWhiteList) => {
    state.wxpayWhiteList = wxpayWhiteList || []
    storage.set('wxpayWhiteList', state.wxpayWhiteList)
  },
  SET_APP_ID: (state, appId) => {
    state.appId = appId || ''
    storage.set('appId', state.appId)
  },
  SET_ENTRY_URL: (state, entryUrl) => {
    state.entryUrl = entryUrl || ''
    storage.set('entryUrl', state.entryUrl)
  },
  SET_WX_AUTH_URL: (state, wxAuthUrl) => {
    state.wxAuthUrl = wxAuthUrl || ''
    storage.set('wxAuthUrl', state.wxAuthUrl)
  }
}

const actions = {
  init(ctx) {
    WxPayApi.getWxPayConfig().then(rsp => {
      console.log(rsp)
      ctx.commit('SET_WXPAY_ENABLE', rsp.wxpay_enable)
      ctx.commit('SET_WXPAY_SANDBOX', rsp.wxpay_enable)
      ctx.commit('SET_WXPAY_WHITE_LIST', rsp.wxpay_whiteList)
      ctx.commit('SET_APP_ID', rsp.appId)
      ctx.commit('SET_ENTRY_URL', rsp.entryUrl)
      ctx.commit('SET_WX_AUTH_URL', rsp.wxAuthUrl)
    }).catch(err => {
      console.error(err)
    })
  }
}

const getters = {
  isPayEnable: (state) => {
    return state.wxpayEnable
  },
  inWhiteList: (state) => (phone) => {
    if (state.wxpayWhiteList && state.wxpayWhiteList.length > 0) {
      return state.wxpayWhiteList.includes(phone)
    } else {
      return true
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

