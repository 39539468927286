import axios from 'axios'
import { Toast } from 'vant'
import store from '@/store'

// create an axios instance
const service = axios.create({
  baseURL: window.appCfg.VUE_APP_BASE_API,
  withCredentials: true,
  timeout: 30000
})

// request interceptor
service.interceptors.request.use(
  config => {
    if (store.state.auth.jwt) {
      config.headers['Authorization'] = 'Bearer ' + store.state.auth.jwt
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.status !== 200) {
      Toast.fail(res.message || 'Error')
      return Promise.reject(new Error(res.message || 'Error'))
    }
    return res
  },
  error => {
    if (error.response) {
      let msg = ''
      switch (error.response.status) {
        case 400: msg = '请求错误(400)' + (error.response.data.message ? (' : ' + error.response.data.message) : ''); break
        case 401: msg = '未授权，请重新登录(401)'; break
        case 403: msg = '权限不足，拒绝访问(403)'; break
        case 404: msg = '资源不存在(404)'; break
        case 408: msg = '请求超时(408)'; break
        case 500: msg = '服务器错误(500)'; break
        case 501: msg = '服务未实现(501)'; break
        case 502: msg = '网络错误(502)'; break
        case 503: msg = '服务不可用(503)'; break
        case 504: msg = '网络超时(504)'; break
        case 505: msg = 'HTTP版本不受支持(505)'; break
        default: msg = '未知错误(' + error.response.status + ')'
      }
      Toast.fail({
        message: msg,
        onClose: function() {
          if (error.response.status === 403) {
            location.hash = '/'
            // if (store.getters.userType === 'CUST') {
            //   Vue.$router.replace('/login')
            // }
            // if (store.getters.userType === 'STAFF') {
            //   console.log(Vue.$router)
            //   Vue.$router.replace('/login')
            // }
          }
        }
      })
    }
    return Promise.reject(error)
  }
)

export default service
