import axios from '@/plugins/request'

export default {
  genRandomId() {
    return 'CLIENT' +
      '-' +
      Math.random().toString(36).substr(2) +
      '-' +
      Math.random().toString(36).substr(2) +
      '-' +
      Math.random().toString(36).substr(2)
  },

  getCaptchaImgUrl(rid) {
    return window.appCfg.VUE_APP_BASE_API +
      '/auth/captcha.jpg?rid=' + rid +
      '&rnd=' + Math.random().toString(36).substr(2)
  },

  staffLogin({ rid, usrCode, usrPwd, captcha }) {
    return axios({
      url: '/auth/staffLogin',
      method: 'GET',
      params: {
        rid: rid,
        usrCode: usrCode,
        usrPwd: usrPwd,
        captcha: captcha
      }
    })
  },

  sendPhonePwd(rid, phone, captcha) {
    return axios({
      url: '/auth/sendPhonePwd',
      method: 'GET',
      params: {
        rid: rid,
        phone: phone,
        captcha: captcha
      }
    })
  },

  custLogin({ rid, phone, pwd }) {
    return axios({
      url: '/auth/custLogin',
      method: 'GET',
      params: {
        rid: rid,
        phone: phone,
        pwd: pwd
      }
    })
  },

  refreshToken() {
    return axios({
      url: '/auth/refreshJwt',
      method: 'GET'
    })
  },

  logout() {

  },

  getAppParams() {
    return axios({
      url: '/auth/params',
      method: 'GET'
    })
  }
}

