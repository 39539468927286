import AuthApi from '@/apis/AuthApi'
import Storages from 'js-storage/js.storage.min'
import JWT from 'jsonwebtoken'

const mode = location.href.toLowerCase().indexOf('/staff.html') > 0 ? '-staff' : ''
const storage = Storages.initNamespaceStorage('auth' + mode).localStorage

const state = {
  jwt: storage.get('jwt') || '',
  user: storage.get('user') || {},
  tenant: storage.get('tenant') || {},
  roles: storage.get('roles') || {},
  jwtExp: storage.get('jwtExp') || 0,
  jwtPayload: storage.get('jwtPayload') || {}
}

const mutations = {
  SET_JWT: (state, jwt) => {
    state.jwt = jwt || ''
    storage.set('jwt', state.jwt)
    state.jwtPayload = JWT.decode(jwt)
    storage.set('jwtPayload', state.jwtPayload || {})
    state.jwtExp = state.jwtPayload ? state.jwtPayload.exp : 0
    storage.set('jwtExp', state.jwtExp)
  },
  SET_USER: (state, user) => {
    state.user = user || {}
    storage.set('user', state.user)
  },
  SET_TENANT: (state, tenant) => {
    state.tenant = tenant || {}
    storage.set('tenant', state.tenant)
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles || []
    storage.set('roles', state.roles)
  }
}

const actions = {
  staffLogin({ commit }, params) {
    return new Promise((resolve, reject) => {
      AuthApi.staffLogin(params).then(rsp => {
        commit('SET_JWT', rsp.jwt)
        commit('SET_USER', rsp.user)
        commit('SET_TENANT', rsp.tenant)
        commit('SET_ROLES', rsp.user.roles)
        resolve()
      }).catch(err => {
        reject(err)
      })
    })
  },

  custLogin({ commit }, params) {
    return new Promise((resolve, reject) => {
      AuthApi.custLogin(params).then(rsp => {
        commit('SET_JWT', rsp.jwt)
        commit('SET_USER', rsp.user)
        commit('SET_TENANT', rsp.tenant)
        commit('SET_ROLES', rsp.roles)
        resolve()
      }).catch(err => {
        reject(err)
      })
    })
  },

  logout({ commit }) {
    commit('SET_JWT', '')
    commit('SET_USER', {})
    commit('SET_TENANT', {})
    commit('SET_ROLES', [])
    return AuthApi.logout()
  },

  refreshJwt({ commit, state }) {
    if (!state.jwt) return
    AuthApi.refreshJwt().then(rsp => {
      if (rsp.data && rsp.data.length > 0) {
        commit('SET_JWT', rsp.data)
      }
    }).catch(err => {
      console.error('refresh token error', err)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
