<template>
  <div>
    <router-view />
    <van-tabbar :route="true" safe-area-inset-bottom>
      <van-tabbar-item to="/cust-vl/mng" :replace="true" icon="logistics">车辆管理</van-tabbar-item>
      <van-tabbar-item to="/cust-bk/mng" :replace="true" icon="todo-list-o">预约记录</van-tabbar-item>
      <van-tabbar-item to="/cust-my/my" :replace="true" icon="user-o">个人信息</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: ''
    }
  }
}
</script>

<style scoped>

</style>
