import { render, staticRenderFns } from "./CustLayout.vue?vue&type=template&id=d21243f2&scoped=true"
import script from "./CustLayout.vue?vue&type=script&lang=js"
export * from "./CustLayout.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d21243f2",
  null
  
)

export default component.exports