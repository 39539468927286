import Vue from 'vue'
import moment from 'moment'

moment.locale('zh-cn')
Vue.prototype.$moment = moment

Vue.filter('datetime', function(val) {
  if (!val) return ''
  return moment(val).format('YYYY-MM-DD HH:mm:ss')
})
Vue.filter('date', function(val) {
  if (!val) return ''
  return moment(val).format('YYYY-MM-DD')
})
Vue.filter('time', function(val) {
  if (!val) return ''
  return moment(val).format('HH:mm')
})
