import Vue from 'vue'

export const Sex = { M: '男', F: '女' }
export const Status = { ON: '启用', OFF: '禁用' }
export const CustType = { OWNER: '车主', AGENT: '代理', BROKER: '中介' }
export const CustChannel = { BIZ: '业务', SELF: '自助' }
export const BkStatus = { BOOKING: '已预约', ONTIME: '已进场', DONE: '已完成', EXPIRED: '已过期', CANCELLED: '已取消' }

const dicts = { Sex, Status, CustType, CustChannel, BkStatus }

for (const key in dicts) {
  Vue.filter('dict' + key, function(val) {
    if (!val) return ''
    return dicts[key][val] || ''
  })
}

Vue.filter('dictBool', function(val) {
  return val ? '是' : '否'
})

Vue.filter('detectType', function(val) {
  if (val === 6) return '半年一检'
  if (val === 12) return '一年一检'
  if (val === 24) return '二年一检'
  return '无法识别'
})

const moneyFmt = Intl.NumberFormat('zh-Hans-CN', {
  style: 'currency', currency: 'CNY', minimumFractionDigits: 2, maximumFractionDigits: 2
})
Vue.filter('money', function(val) {
  return moneyFmt.format(val || 0)
})

const intFmt = Intl.NumberFormat('zh-Hans-CN', {
  style: 'decimal', maximumFractionDigits: 0
})
Vue.filter('intNum', function(val) {
  return intFmt.format(val || 0)
})

const float2Fmt = Intl.NumberFormat('zh-Hans-CN', {
  style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2
})
Vue.filter('float2Num', function(val) {
  return float2Fmt.format(val || 0)
})
