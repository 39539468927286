import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'

Vue.use(Vuex)

// load global store
const modulesFiles = require.context('./modules/', true, /.*\.js$/)
const modules = modulesFiles.keys().reduce((modules, path) => {
  const moduleName = path.replace(/^\.\/(.*).js$/, '$1')
  const value = modulesFiles(path)
  modules[moduleName] = value.default
  return modules
}, {})

const store = new Vuex.Store({
  modules,
  getters
})

store.dispatch('env/init')
store.dispatch('wxpay/init')

export default store
