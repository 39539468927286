import Vue from 'vue'

Vue.prototype.$appCfg = window.appCfg
document.title = window.appCfg.app_title

import '@/plugins/vant'
import '@/plugins/moment'
import '@/components/style.css'
import '@/plugins/dict'

import store from '@/store'
import router from './router'

import App from './App.vue'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
