import axios from '@/plugins/request'

export default {
  getWxPayConfig() {
    return axios({
      url: '/wxpay/config',
      method: 'GET'
    })
  },

  getWxAuthUrl(wxAuthUrl, appId, entryUrl, bkId) {
    let tmp = wxAuthUrl
    tmp = tmp.replace('{appId}', appId)
    tmp = tmp.replace('{entryUrl}', encodeURIComponent(entryUrl))
    tmp = tmp.replace('{bkId}', bkId)
    return tmp
  },

  getPayItems(bkId) {
    return axios({
      url: '/wxpay/payItems',
      method: 'GET',
      params: {
        bkId: bkId
      }
    })
  },

  genPayOrder(params) {
    return axios({
      url: '/wxpay/genOrder',
      method: 'POST',
      data: params
    })
  },

  getPayResult(bkId, prePayId) {
    return axios({
      url: '/wxpay/getPayResult',
      method: 'GET',
      params: {
        bkId: bkId,
        prePayId: prePayId
      }
    })
  }
}
