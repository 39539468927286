import Vue from 'vue'
import Router from 'vue-router'
import CustLayout from '@/components/CustLayout'

Vue.use(Router)

const routes = [
  {
    path: '/',
    component: () => import('@/views/Splash.vue')
  },
  {
    path: '/login',
    component: () => import('@/views/cust/Login.vue')
  }, {
    path: '/cust-my',
    component: CustLayout,
    children: [
      {
        path: 'my',
        component: () => import('@/views/cust/my/My.vue')
      }
    ]
  }, {
    path: '/cust-bk',
    component: CustLayout,
    children: [
      {
        path: 'mng',
        component: () => import('@/views/cust/bk/Mng.vue')
      }, {
        path: 'makebooking',
        component: () => import('@/views/cust/bk/MakeBooking.vue')
      }
    ]
  }, {
    path: '/cust-vl',
    component: CustLayout,
    children: [
      {
        path: 'mng',
        component: () => import('@/views/cust/vl/Mng.vue')
      }, {
        path: 'edit/:id',
        component: () => import('@/views/cust/vl/Edit.vue')
      }
    ]
  }, {
    path: '/ocr',
    component: () => import('@/views/ocr/VLOCR.vue')
  }, {
    path: '/ocr-result',
    component: () => import('@/views/ocr/VLOCRResult.vue')
  }
]

const router = new Router({
  routes: routes
})

export default router
