const getters = {
  userType: state => state.auth.jwtPayload ? state.auth.jwtPayload.userType : undefined,
  jwt: state => state.auth.jwt,
  isExpired: state => state.auth.jwtExp > 0 && state.auth.jwtExp * 1000 < new Date().getTime(),
  tenant: state => state.auth.tenant,
  user: state => state.auth.user,
  vlMax: state => { // 客户vl上限
    let max = 0
    switch (state.auth.user.type) {
      case 'OWNER':
        max = state.env.booking_vl_owner_max
        break
      case 'BROKER':
        max = state.env.booking_vl_broker_max
        break
      case 'AGENT':
        max = state.env.booking_vl_agent_max
        break
    }
    return max || 0
  },
  hasRole: (state) => (role) => { return state.auth.roles.includes('ADMIN') || state.auth.roles.includes(role) }
}
export default getters
