import Vue from 'vue'
import Storages from 'js-storage/js.storage.min'
import AuthApi from '@/apis/AuthApi'

const storage = Storages.initNamespaceStorage('env').localStorage

const state = {
}

const mutations = {
}

const actions = {
  init(ctx) {
    if (storage.keys) {
      storage.keys().forEach(key => {
        Vue.set(ctx.state, key, storage.get(key))
      })
    }

    AuthApi.getAppParams().then(rsp => {
      for (const key in rsp.data) {
        Vue.set(ctx.state, key, rsp.data[key])
        storage.set(key, rsp.data[key])
      }
    }).catch(err => {
      console.error(err)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
