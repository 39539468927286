import Storages from 'js-storage/js.storage.min'

const storage = Storages.initNamespaceStorage('reme').localStorage

const state = {
  rememberMe: storage.get('rememberMe') || true,
  usrCode: storage.get('usrCode') || '',
  phone: storage.get('phone') || ''
}

const mutations = {
  SET_REMEMBER_ME: (state, reme) => {
    state.rememberMe = reme
    storage.set('rememberMe', state.rememberMe)
  },
  SET_USR_CODE: (state, usrCode) => {
    state.usrCode = usrCode || ''
    storage.set('usrCode', state.usrCode)
  },
  SET_PHONE: (state, phone) => {
    state.phone = phone || ''
    storage.set('phone', state.phone)
  }
}

const actions = {
  doReme(ctx, { rememberMe, usrCode, phone }) {
    ctx.commit('SET_REMEMBER_ME', rememberMe)
    ctx.commit('SET_USR_CODE', rememberMe ? usrCode : '')
    ctx.commit('SET_PHONE', rememberMe ? phone : '')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
